const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#home', // Scroll için id belirttik
    },
    {
        id: 4,
        name: 'About',
        links: '#about', // Scroll için id belirttik  
    },
    {
        id: 5,
        name: 'RoadMap',
        links: '#roadmap',  
    },
    {
        id: 6,
        name: 'FAQs',
        links: '#faqs',        
    },
    {
        id: 7,
        name: 'Contact',
        links: '/contact', // Tam sayfa yönlendirme
    },

    
]

export default menus;