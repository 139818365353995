import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';

import img from '../../assets/images/layouts/about-06.png'
import brand from '../../assets/images/about/brand-01.png'
/*
Empowering Web3 Brands
Through Swag and Digital Services



*/
function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'Empowering <br> Web3 Brands',
            desc1: 'WikZ provides a one-stop solution for web3 projects and influencers to create branded stores, managing everything from design to delivery. Brands focus on engagement while fans access exclusive merch. Influencers can also offer services like promotions and digital content.',
            desc2: 'WikZ Digital offers bill payments, eSIMs, and gift cards. Powered by TON blockchain and integrated with Telegram, users can access physical and digital products, earning rewards as they shop.',
           
        }
    )
    return (
        <section className="about" id="about" style={{
            marginTop: '200px',
            minHeight: '100vh !important'
        }}>
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading" dangerouslySetInnerHTML={{ __html: dataBlock.heading }}></h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <img className='mb-5' style={{
                                            maxWidth: '100%',
                                            height: 'auto'
                                        }} src={brand} alt="WikZ" />
                                    </div>
                                  
                                    <p className="mb-17" style={{
                                        fontSize: '1.2rem'
                                    }}>{dataBlock.desc1}</p>
                                    <p className="mb-30" style={{
                                        fontSize: '1.2rem'
                                    }}>{dataBlock.desc2}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About2;