


const dataFaqs = [
    {
        id: 1,
        title: 'How do I participate in the WikZ airdrop?',
        text : 'To participate in the WikZ airdrop, simply open the WikZ Miniapp on Telegram and start playing. Connect your wallet and engage in daily activities to automatically qualify for airdrop rewards.',
        show: ''
    
    },
    {
        id: 2,
        title: ' What is the best way to collect NFT?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 3,
        title: ' What is the best way to collect NFT?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 4,
        title: 'What is the best way to collect NFT?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 5,
        title: 'When the musics over turn off the light?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },
    {
        id: 6,
        title: ' What is the best way to collect NFT?',
        text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Tellus aliquam parturient erat id vel, condimentum a,hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis.',
        show: ''
    
    },   
]

export default dataFaqs;