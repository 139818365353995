import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import wikz_circle_logo from "../../assets/images/logo/wikz_circle_logo.png"


import { EffectCoverflow, Autoplay, Pagination, Navigation } from "swiper";
import Button from '../button/Button';
import About2 from '../about/About2';

import "./style.css"

Banner2.propTypes = {
    data: PropTypes.array
};


function Banner2(props) {
    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'Telegram’s Premier Web3 Store',
            heading: `Join Now <br> Start Earning`,
            desc: 'Experience the future of shopping with WikZ! Launch the WikZ app, spin the wheel daily, and earn rewards while you shop for digital and physical products. Every now and then, a platform comes along that reshapes entire industries—this could be your chance to be part of the next big thing.',
        }
    )
    return (
        <section class="banner s2 mt-0" id="home" style={{
            minHeight: '100vh !important'
        }}>
            <div className="shape"></div>
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            {/* TODO: Kontrol edilecek*/}
                            <h2 className="heading pd" dangerouslySetInnerHTML={{ __html: dataBlock.heading }} />
                            <p style={{
                                fontSize: '1.2rem'
                            }} className="mb-34" dangerouslySetInnerHTML={{ __html: dataBlock.desc }}>

                            </p>
                            <Button title='Start WikZ' link='/contact' />
                        </div>

                        <About2 />

                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                500: {
                                    slidesPerView: 2,
                                },
                                700: {
                                    slidesPerView: 3,
                                },
                            }}
                            coverflowEffect={{
                                rotate: 30,
                                stretch: 15,
                                depth: 320,
                                modifier: 1,
                                slideShadows: false,
                            }}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}



                            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                            className="bannerSwiper"
                        >

                            {
                                data.map(idx => (
                                    <SwiperSlide key={idx.id}>
                                        <div className="card-box" >
                                            <div className="top d-flex">
                                                <img
                                                    src={wikz_circle_logo}
                                                    alt="WikZ"
                                                    style={{ width: '45px', height: '45px', marginRight: '10px' }}
                                                />
                                                <div>
                                                    <h6>{idx.title}</h6>
                                                    <h6 className="price">{idx.price}</h6>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="image">
                                                    <img src={idx.img} alt="WikZ" />
                                                </div>

                                                <div className="info d-flex">
                                                    <img src={idx.avt} alt="WikZ" />

                                                    <div >
                                                        <h6 className="name">{idx.name}</h6>
                                                        <p>{idx.tag}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner2;