import React , {useState} from 'react';
import Button from '../button/Button';

import img from '../../assets/images/layouts/create.png'
import createBrand from '../../assets/images/create/create_brand.png'

function Create(props) {


    const [dataBlock] = useState(
        {
            heading: 'Grow Your Brand with WikZ',
            desc: `Partner with us to launch your official store, engage your community, and maximize your earnings.`,
            desc2: 'Start selling with WikZ today!' 
        }
    )
    return (
        <section className="create" style={{
            marginTop: '10rem'
        }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">{dataBlock.heading}</h4>
                                    <p>{dataBlock.desc}</p>
                                    <p>{dataBlock.desc2}</p>
                                    <Button title='Join Now' link='/contact' />
                                
                                </div>
                                <img src={createBrand} alt="WikZ" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Create;