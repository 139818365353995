import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import menus from '../../pages/menu';
import logo from '../../assets/images/logo/logo.png';
import throttle from 'lodash/throttle';
import './styles.scss';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [menuActive, setMenuActive] = useState(false);
    const [activeSection, setActiveSection] = useState('home');

    // Scroll olayı için throttle uygulama
    const handleScroll = throttle(() => {
        setScroll(window.scrollY > 10);
    }, 200);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Aktif bölüm kontrolü
    useEffect(() => {
        const handleScrollCheck = () => {
            const sections = ['home', 'about', 'roadmap', 'faqs'];
            const scrollPosition = window.scrollY + 70; // Başlık yüksekliği

            sections.forEach((section) => {
                const sectionElement = document.getElementById(section);
                if (sectionElement) {
                    const sectionTop = sectionElement.offsetTop;
                    const sectionHeight = sectionElement.clientHeight;
                    if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                        setActiveSection(section);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScrollCheck);
        return () => window.removeEventListener('scroll', handleScrollCheck);
    }, []);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const handleScrollLinkClick = (sectionId) => {
        setActiveSection(sectionId); // Aktif bölümü güncelle
        if (location.pathname === '/contact') {
            navigate('/'); // Contact sayfasındayken anasayfaya git
        }
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container big">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body">
                            <div className="header__logo">
                                <ScrollLink to="home" smooth={true} duration={500}>
                                    <img id="site-logo" src={logo} alt='WikZ' style={{ width: '200px', height: 'auto' }} />
                                </ScrollLink>
                            </div>

                            <div className="header__right">
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {menus.map((data, idx) => (
                                            <li key={idx}>
                                                {data.name === "Contact" ? (
                                                    <Link
                                                    className={activeSection === 'contact' ? 'active' : ''}
                                                        to={data.links}
                                                        onClick={() => {
                                                            handleMenuActive();
                                                            setActiveSection('contact');
                                                        }}
                                                    >
                                                        {data.name}
                                                    </Link>
                                                ) : (
                                                    <ScrollLink
                                                        to={data.links.slice(1)} // "#about" -> "about"
                                                        smooth={true}
                                                        duration={500}
                                                        offset={-70}
                                                        className={activeSection === data.links.slice(1) ? 'active' : ''}
                                                        onClick={() => {
                                                            handleMenuActive();
                                                            handleScrollLinkClick(data.links.slice(1));
                                                        }}
                                                    >
                                                        {data.name}
                                                    </ScrollLink>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                <div className={`mobile-button`} onClick={handleMenuActive}><span></span></div>
                            </div>

                            <div className="header__action">
                                <Link to="/contact" className="action-btn">
                                    <span>Join Now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {menuActive && window.innerWidth <= 768 && (
                <div
                    onClick={handleMenuActive}
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        background: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(8px)',
                        zIndex: '9999'
                    }}
                />
            )}
        </header>
    );
}

export default Header;