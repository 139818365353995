
import blumImg from '../images/card/Blum.png'
import catizenImg from '../images/card/Catizen.png'
import dogsImg from '../images/card/Dogs.png'
import notCoinImg from '../images/card/Notcoin.png'
import pavelImg from '../images/card/Pavel.png'
import tonImg from '../images/card/Ton.png'

import blumLogo from '../images/logo/card/Blum_Logo.png'
import catizenLogo from '../images/logo/card/Catizen_Logo.png'
import dogsLogo from '../images/logo/card/Dogs_Logo.png'
import notCoinLogo from '../images/logo/card/NotCoin_Logo.png'
import pavelLogo from '../images/logo/card/PavelDurov_Logo.png'
import tonLogo from '../images/logo/card/Ton_Logo.png'

const dataCard = [

    {
        id: 1,
        img: blumImg,
        title: 'BLUM BLACK GLOSSY MUG',
        price : 'DRINKWARE',
        avt: blumLogo,
        name: 'Blum',
        tag: '@BlumCrypto'
    },
    {
        id: 2,
        img: catizenImg,
        title: 'CATIZEN CLASSIC BACKPACK',
        price : 'BAGS',
        avt: catizenLogo,
        name: 'Catizen',
        tag: '@CatizenAnn'
    },
    {
        id: 3,
        img: dogsImg,
        title: 'DOGS OUT UNISEX HOODIE',
        price : 'HOODIES',
        avt: dogsLogo,
        name: 'Dogs',
        tag: '@Dogs_community'
    },
    {
        id: 4,
        img: notCoinImg,
        title: 'HOUSE OF NOTCOIN T-SHIRT',
        price : 'T-SHIRTS',
        avt: notCoinLogo,
        name: 'Not Coin',
        tag: '@Notcoin'
    },
    {
        id: 5,
        img: pavelImg,
        title: 'PAVEL’S ARMY CASE FOR IPHONE®',
        price : 'ACCESSORIES',
        avt: pavelLogo,
        name: 'Pavel Durov',
        tag: '@Durov'
    },
    {
        id: 5,
        img: tonImg,
        title: 'TON CLASSIC TOWEL',
        price : 'HOME & LIVING',
        avt: tonLogo,
        name: 'The Open Network',
        tag: '@Toncoin'
    },
]

export default dataCard;