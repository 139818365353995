import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo/logo.png'
import { Link as ScrollLink } from 'react-scroll';


function Footer(props) {

    const [dataBlock] = useState(
        {
            heading: 'Get Newsletter',
            desc: 'Get udpated with news, tips & tricks'
        }
    )

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer className="footer">

            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">


                        <div className="footer__bottom">
                            <Link to="/" className="logo">
                                <img style={{ width: '100px' }} src={logo} alt="WikZ" />
                            </Link>

                            <div className="center mb--30">
                                <ul className="list">
                                    <li><ScrollLink to="home">Home</ScrollLink></li>
                                    <li><ScrollLink to="about">About</ScrollLink></li>
                                    <li><ScrollLink to="roadmap">RoadMap</ScrollLink></li>
                                    <li><ScrollLink to="fags">FAQs</ScrollLink></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                                <p>Copyright © 2024, WikZ</p>
                            </div>
                            <ul className="list-social">
                                <li><Link to="https://t.me/WikZCrypto">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="w-[1.5rem] h-[1.5rem] md:w-5 md:h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
                                </Link></li>
                                <li>
                                    <Link to="https://x.com/WikZCrypto">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="w-[1.5rem] h-[1.5rem] md:w-5 md:h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path></svg>
                                </Link>
                                </li>
                                <li><Link to="https://wikz.gitbook.io/">
                                <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="w-[1.5rem] h-[1.5rem] md:w-5 md:h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 18H17V16H7V18Z" fill="currentColor"></path><path d="M17 14H7V12H17V14Z" fill="currentColor"></path><path d="M7 10H11V8H7V10Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path></svg>
                                </Link></li>
                                
                            </ul>
                        </div>


                    </div>
                </div>
            </div>

            {
                isVisible &&
                <Link to='#' onClick={scrollToTop} id="scroll-top"><span className="icon-arrow-top"></span></Link>
            }
        </footer>
    );
}

export default Footer;