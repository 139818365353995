

const dataRoadMap = [

    {
        id: 1,
        time: '2024 - Q3 & Q4',
        title: 'Beginning',
        desc1:`● WikZ Miniapp`,
        desc2:`● Project & Influencer Partnerships,`,
        desc3:`● WikZ Swag`,
        desc4:`● Exchange Listing`,

        class: ''
    },
    {
        id: 2,
        time: '2025 - Q1 & Q4',
        title: 'Expansion',
        desc1: '● NFT Receipts & Gift Cards',
        desc2: '● WikZ Digital ',
        desc3: '● Enhanced Staking ',
        class: 'right'
    },
    {
        id: 3,
        time: '2026 - Q1 & Q4',
        title: 'Revolution',
        desc1: '● Physical Store in Crypto Events',
        desc2: '● Enhanced Ads Platform',
        desc3: '● WikZ Digital - Ingame assets, NFT Collectibles',
        class: ''
    },


]

export default dataRoadMap;