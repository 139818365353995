import React from 'react';

import dataFaqs from '../assets/fake-data/data-faq';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';

import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';


//TODO
import Banner2 from '../components/banner/Banner2';
import dataCard from '../assets/fake-data/data-card';
import About2 from '../components/about/About2';
import Roadmap2 from '../components/roadmap/Roadmap2';
function Home01(props) {

    return (
        <div className="home-1 wrapper">
            <Banner2 data={dataCard} />
            <Portfolio data={dataPortfolio} />
            <Create />
            <Roadmap2 data={dataRoadMap} />
            <Faqs data={dataFaqs} />
            <Footer />
        </div>
        

    );
}

export default Home01;