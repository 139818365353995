
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Connect with WikZ',
        desc: 'We make it easy. First, connect your brand to WikZ through our straightforward onboarding process. Our team will guide you every step of the way to ensure a smooth start.'
    },
    {
        id: 2,
        img: img2,
        title: 'Design with Us',
        desc: 'Work closely with our design partner to create merchandise that truly reflects your brand. We’ll collaborate on design concepts tailored to your vision, and once the designs are approved, we’ll move on to finalize the pricing details to fit your brand’s strategy.'
    },
    {
        id: 3,
        img: img3,
        title: 'Set Up & Launch',
        desc: 'We take care of setting up your store and preparing for the launch. This includes managing logistics, customer service, and coordinating the entire sales process. You’ll focus on co-marketing activities and community engagement, while we ensure a successful launch.'
    },
    {
        id: 4,
        img: img4,
        title: 'Sell & Earn',
        desc: 'With your store live, sales begin! We continue to handle all operations, giving you more time to engage with your audience. Monitor all aspects of the sales cycle in real-time through your dashboard miniapp for full transparency.'
    },


]

export default dataPortfolio;