import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


function Faqs() {

    const [dataBlock] = useState(
        {
            subheading: 'FAQs',
            heading: 'Frequently Aksed Questions',
            desc1: 'Find quick answers to common questions about using WikZ. Review this FAQ before reaching out for additional support',
            desc2: 'Please check this FAQ first before contacting us.'

        }
    )




    const dataFaqs = {
        airdropGame: [
            {
                id: 1,
                title: 'How do I participate in the WikZ airdrop?',
                text: 'To participate in the WikZ airdrop, simply open the WikZ Miniapp on Telegram and start playing. Connect your wallet and engage in daily activities to automatically qualify for airdrop rewards.',
                show: ''

            },
            {
                id: 2,
                title: 'What rewards can I earn in the game?',
                text: 'In the game, you can earn WIKZ tokens, in-app USD rewards, other tokens like TON, and in-game currencies. These in-game currencies can be used to increase your chances of airdrop allocations. The more you engage, the more diverse rewards and benefits you unlock.',
                show: ''

            },
            {
                id: 3,
                title: 'How do USD rewards work, and how can I use them?',
                text: 'USD rewards earned in the game can be used directly within the app for purchases. These rewards allow you to shop for various items without any additional requirements.',
                show: ''

            },
            {
                id: 4,
                title: 'How does the spin wheel work in the game?',
                text: 'The spin wheel can be used up to three times a day to win various rewards. Spin the wheel and claim rewards, which may include WIKZ tokens, in-app USD rewards, other tokens like TON, or in-game currencies that can be used within the app.',
                show: ''

            },
            {
                id: 5,
                title: 'Can I withdraw my rewards from the game?',
                text: 'Yes, you can withdraw your earned rewards by connecting your TON wallet. You can claim your WIKZ tokens and other rewards before the token listing, and they will be transferred directly to your wallet once the listing goes live.',
                show: ''

            },
            {
                id: 6,
                title: 'How are airdrop allocations determined?',
                text: 'Airdrop allocations are based on your level of engagement within the WikZ ecosystem. By participating in the game, completing tasks, and making purchases, you increase your chances of receiving a larger share of the airdrop.',
                show: ''

            },
        ],
        shoppers: [
            {
                id: 1,
                title: 'How do I make a purchase on WikZ?',
                text: 'You can browse and shop directly within the WikZ Miniapp on Telegram. Choose the items you want, add them to your cart, and proceed to checkout. Payments can be made using in-app USD rewards, TON, USDT, NFT gift cards, or credit cards. We also plan to add support for other cryptocurrencies in the near future.',
                show: ''
            },
            {
                id: 2,
                title: 'How do NFT gift cards work for purchases?',
                text: 'NFT gift cards can be used directly within the app for purchases. These gift cards can be sent from customer to customer or created by projects for their communities to use in their corresponding stores. Select the NFT gift card option during checkout to redeem it for products.',
                show: ''
            },
            {
                id: 3,
                title: 'What payment methods are available?',
                text: 'WikZ offers multiple payment options, including TON, USDT, in-app USD rewards, and NFT gift cards. Choose your preferred payment method during checkout to complete your purchase.',
                show: ''
            },
            {
                id: 4,
                title: 'How do I track my order?',
                text: 'After completing your purchase, you can track your order through the dashboard within the WikZ Miniapp. Here, youll find detailed information about your orders, including their current status, shipping details, and exact location in real-time.',
                show: ''
            },
            {
                id: 5,
                title: 'What is the return policy?',
                text: 'WikZ allows returns for eligible products within a 14-day period. To initiate a return, go to the "Order History" section in the app and follow the instructions. Our customer service team is also available to assist you with the process.',
                show: ''
            },
            {
                id: 6,
                title: 'How do I contact customer service?',
                text: 'For customer support, WikZ offers 24/7 live support on Telegram. You can also reach out via email for assistance with any questions or issues related to your purchases.',
                show: ''
            }
        ],
        sellers: [
            {
                id: 1,
                title: 'How do I become a seller on WikZ?',
                text: 'To become a seller on WikZ, sign up and complete your profile. You can start listing your products immediately.',
                show: ''
            },
            {
                id: 2,
                title: 'What support does WikZ offer to sellers?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. WikZ charges competitive fees for sellers.',
                show: ''
            },
            {
                id: 3,
                title: 'How does pricing work for my products?',
                text: 'During the onboarding process, we work with you to determine the pricing for your products. Our team ensures that pricing aligns with your brand and meets the needs of your target audience.',
                show: ''
            },
            {
                id: 4,
                title: 'How are payments processed and received?',
                text: 'WikZ handles all payment processing on your behalf. After each sale, your earnings are tracked in real-time on your dashboard. Payments are disbursed regularly and can be claimed directly to your TON wallet.',
                show: ''
            },
            {
                id: 5,
                title: 'How do I track my sales and performance?',
                text: 'You can track all sales and performance metrics through the WikZ Miniapp dashboard. It provides detailed analytics on orders, revenue, customer inquiries, and the effectiveness of marketing activities, offering a transparent view of your store’s performance.',
                show: ''
            },
            {
                id: 6,
                title: 'What are the co-marketing requirements for sellers?',
                text: 'Sellers on WikZ are encouraged to participate in co-marketing activities to maximize reach and engagement. Our team collaborates with you to create joint campaigns and promotional strategies that resonate with both your community and the broader WikZ ecosystem. Additionally, when people purchase items from your store, they will be eligible for the WikZ airdrop and earn WIKZ tokens, providing extra incentives for both your audience and the WikZ community.',
                show: ''
            },
        ],
        wikzCoin: [
            {
                id: 8,
                title: 'How does pricing work for my products?',
                text: 'WikZ Coin is the native cryptocurrency used within the WikZ ecosystem.',
                show: ''
            },
            {
                id: 9,
                title: 'How can I use WikZ Coin?',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. WikZ Coin can be used for various transactions.',
                show: ''
            }
        ]
    }


    const [dataTab] = useState([
        {
            id: 1,
            title: 'Airdrop & Game',
        },
        {
            id: 2,
            title: 'Shoppers',
        },
        {
            id: 3,
            title: 'Sellers',
        },


    ]);

    return (
        <section className="faq" id='faqs'>
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center" data-aos="fade-down" data-aos-duration="3000">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="mb-17">{dataBlock.desc1}</p>
                            <p>{dataBlock.desc2}</p>
                        </div>

                        <div className="faq__main flat-tabs">

                            <Tabs>
                                <TabList className='menu-tab'>
                                    {dataTab.map((tab) => (
                                        <Tab className='fs-14 h6' key={tab.id}>{tab.title}</Tab>
                                    ))}
                                </TabList>

                                {/* Airdrop & Game Tab */}
                                <TabPanel className='content-tab'>
                                    <div className="content-inner">
                                        <div className="flat-accordion row">
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.airdropGame.slice(0, 3).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.airdropGame.slice(3, 6).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* Shoppers Tab */}
                                <TabPanel className='content-tab'>
                                    <div className="content-inner">
                                        <div className="flat-accordion row">
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.shoppers.slice(0, 3).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.shoppers.slice(3, 6).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* Sellers Tab */}
                                <TabPanel className='content-tab'>
                                    <div className="content-inner">
                                        <div className="flat-accordion row">
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.sellers.slice(0, 3).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.sellers.slice(3, 6).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                {/* WikZ Coin Tab */}
                                <TabPanel className='content-tab'>
                                    <div className="content-inner">
                                        <div className="flat-accordion row">
                                            <div className="col-md-6 col-sm-12">
                                                {dataFaqs.wikzCoin.slice(3, 6).map((faq) => (
                                                    <Accordion show={faq.show} key={faq.id} title={faq.title} className='flat-toggle h6'>
                                                        <p className="toggle-content">{faq.text}</p>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Faqs;